
const APP_STATUS = {
  SUBMIT: 1,
  RETURN: 2,
  RECEIPT: 3,
  EVALUATED: 4,
  TEMPSAVE: 5,
  ADOPTED: 10,
  UNADOPTED: 11,
  REJECTED: 12,
  WITHDREW: 13,
};

const APP_STATUS_TEXTS = {
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.RECEIPT]: '受領',
  [APP_STATUS.EVALUATED]: '選考評価済',
  [APP_STATUS.TEMPSAVE]: '一時保存',
  [APP_STATUS.ADOPTED]: '採択',
  [APP_STATUS.UNADOPTED]: '不採択',
  [APP_STATUS.REJECTED]: '棄却',
  [APP_STATUS.WITHDREW]: '取下',
};

// 管理画面で表示するステータスのセレクトボックス値
const CMS_APP_STATUS_OPTION = {
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.REJECTED]: '棄却',
  [APP_STATUS.RECEIPT]: '受領',
};

const CMS_APP_STATUS_OPTION_4_LIST = {
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.RECEIPT]: '受領',
  [APP_STATUS.ADOPTED]: '採択',
  [APP_STATUS.UNADOPTED]: '不採択',
  [APP_STATUS.REJECTED]: '棄却',
};

const CMS_REP_STATUS_OPTION = {
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.RECEIPT]: '受領',
};

const CMS_REP_STATUS_OPTION_4_LIST = {
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RECEIPT]: '受領',
  [APP_STATUS.RETURN]: '差戻',
};

// 返却理由を持つ（表示や書き込みができる）ステータス
const HAS_REASON_STATUS = [
  APP_STATUS.RETURN,
  APP_STATUS.REJECTED,
  APP_STATUS.UNADOPTED,
];

// 一般画面で現在のステータスとかを表示するステータス
const GAS_VIEW_CONTROL_STATUS = [
  APP_STATUS.SUBMIT,
  APP_STATUS.RETURN,
  APP_STATUS.RECEIPT,
  APP_STATUS.EVALUATED,
  APP_STATUS.ADOPTED,
  APP_STATUS.UNADOPTED,
  APP_STATUS.REJECTED,
];

const FROM_STATUS = {
  INIT: 1, // 新規登録
  EDIT: 2, // 編集登録
  VIEW: 3, // 閲覧オンリー
  NONE: 0, // 閲覧さえ不可
};

const CHANGE_DATA_STATUS = {
  CHANGE: 1, // 変更あり
  NONE: 0, // 変更なし
};

const CHANGE_DATA_STATUS_TEXT = {
  [CHANGE_DATA_STATUS.CHANGE]: 'あり', // 変更あり
  [CHANGE_DATA_STATUS.NONE]: 'なし', // 変更なし
};

// 選考用ステータス
const JUDGMENT_STATUS_OPTION = {
  1: '合格',
  0: '不合格',
};

export default {
  APP_STATUS,
  APP_STATUS_TEXTS,
  CMS_APP_STATUS_OPTION,
  CMS_APP_STATUS_OPTION_4_LIST,
  CMS_REP_STATUS_OPTION,
  CMS_REP_STATUS_OPTION_4_LIST,
  HAS_REASON_STATUS,
  GAS_VIEW_CONTROL_STATUS,
  FROM_STATUS,
  CHANGE_DATA_STATUS,
  CHANGE_DATA_STATUS_TEXT,
  JUDGMENT_STATUS_OPTION,
};
