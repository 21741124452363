<template>
  <div>
    <div id="appForm" class="mx-auto col-11 mt-5">
      <div class="flex flexLeft mx-auto contentsWidth">
        <p class="h4 text-center fw-3">{{year}}年度 {{name}} - 追加資料</p>
      </div>
      <hr class="title contentsWidth">
      <b-tabs
        class="gas-tab mb-3 mx-auto contentsWidth"
        nav-wrapper-class="gas-tab-wrap">
        <b-tab
          @click="scrollBar(index)"
          v-for="(page, index) in pages" :key="index">
          <template v-slot:title>
            <span>{{page.name}}</span>
            <img
              v-if="hasPageErr[index]"
              class="errorMark"
              src="@/assets/svg/error.svg">
          </template>
          <v-app-form
            :writable="canSend"
            :pageIndex="index"
            :assets="assets"
            :downloadFormFile="downloadFormFile"
            :downloadOpenFile="downloadOpenFile"/>
        </b-tab>
      </b-tabs>

      <div class="mt-4 mb-3 flex flexCenter contentsWidth">
        <b-button class="btn btn-lg mr-4 bold" to="/top">トップに戻る</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';
import download from '@/modules/download';
import AppForm from '@/components/gas/parts/Form.vue';
import CONST_STATUS from '@/constants/appStatus';


export default {
  name: 'gasAttachmentForm',
  components: {
    'v-app-form': AppForm,
  },
  data() {
    return {
      isEdit: false,
      appId: null,
      apptypeId: null,
      year: null,
      name: '',
      code: null,
      reason: '',
      status: null,
      hasUserErr: false,
      hasPageErr: [],
      initTabChange: {},
      onTime: null,
      viewLocateMsg: true,
      formStatus: null,
      assets: {},
      close_datetime: null,
      pageErrs: [],
    };
  },
  computed: {
    pages() {
      return this.$store.state.gasApplicationForm.pages;
    },
    hasEmpty() {
      // 入力フォーム
      const { inputs } = this.$store.state.gasApplicationInput;
      const formOptionData = this.$store.state.gasApplicationForm.optionDataList;
      const findFormResult = inputs.find((input) => {
        if (formOptionData[input.option_id] && !formOptionData[input.option_id].is_required) {
          return false;
        }
        const noSelect = input.field_answer_id === null;
        const notext = input.field_answer_text === '';
        const noFile = input.uploadedFileKey === '';
        return noSelect && notext && noFile;
      });
      return typeof findFormResult !== 'undefined';
    },
    isUnsubmited() {
      return this.status === null || this.status === CONST_STATUS.APP_STATUS.TEMPSAVE;
    },
    isSubmited() {
      return this.status === CONST_STATUS.APP_STATUS.SUBMIT;
    },
    viewControl() {
      const viewControlStatusList = CONST_STATUS.GAS_VIEW_CONTROL_STATUS;
      return viewControlStatusList.indexOf(this.status) !== -1;
    },
    formStatuses() {
      return CONST_STATUS.FROM_STATUS;
    },
    canSend() {
      return false;
    },
    hasChange() {
      return this.$store.state.gasApplicationForm.hasChange;
    },
    allPageNo() {
      return this.pages.length; // 動的なページとユーザー情報の和
    },
    textIsEdit() {
      if (!this.isEdit) {
        return '提出';
      }
      return '再提出';
    },
  },
  methods: {
    async fetchApptypeData(apptypeId) {
      const param = {
        apptypeId,
      };
      const promiseFuncs = [
        api.send('/api/attachments/myatt', param),
        api.send('/api/attachments/form', param),
        api.send('/api/attachments/edit', param),
      ];
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.error(err);
        });
      if (!responses) {
        await this.alert('データを取得できませんでした。');
        this.$router.push({ name: 'gasTop' });
        return;
      }
      const { myatt, formStatus } = responses[0].data;
      if (myatt !== null) {
        this.appId = myatt.application_id;
        this.year = myatt.year;
        this.name = myatt.name;
        this.code = myatt.code;
        this.status = myatt.status;
        this.reason = myatt.reason;
      }
      this.formStatus = formStatus;
      const { pages } = responses[1].data;
      this.$store.commit('gasApplicationForm/setDbData', pages);
      const { answers, attId } = responses[2].data;
      this.$store.commit('gasApplicationInput/setDbData', { pages, answers });
      this.$store.commit('gasApplicationForm/setAttachmentId', attId);
    },
    async tempSave() {
      this.pageErrs = [];
      if (!await this.confirm('現在の内容で一時保存をしてもよろしいですか？')) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        appId: this.appId,
        apptypeId: this.apptypeId,
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/attachments/tempSave', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.$store.commit('userInfo/resetErrorMsg');
      this.$store.commit('gasApplicationInput/initErrMessages');
      this.$store.commit('gasApplicationForm/setHasChange', false);
      this.hasUserErr = false;
      this.hasPageErr = [];
      await this.alert('一時保存しました。');
    },
    async save() {
      if (!await this.confirm('現在の内容で提出をしてもよろしいですか？')) {
        return;
      }
      this.pageErrs = [];
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        appId: this.appId,
        apptypeId: this.apptypeId,
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/attachments/save', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.pageErrs = err.response.data.pageErrs;
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.$store.commit('gasApplicationInput/setCode', response.data.code);
      // リダイレクト
      this.$router.push({ name: 'gasAttachmentEnd', meta: { locate: false } });
    },
    async downloadOpenFile(assetId, assetName) {
      const param = {
        apptypeId: this.apptypeId,
        assetId,
      };
      this.$store.dispatch('page/onWaiting');
      const requireBlob = true;
      const response = await api.send('/api/attachments/download/file/form', param, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, assetName);
    },
    async downloadFormFile(fieldId, optionId, fileName) {
      const param = {
        attachment_id: this.$store.state.gasApplicationForm.attachment_id,
        field_id: fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
      };
      const url = '/api/attachments/download/uploadedFile';
      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    setErrorMarks(userErrs) {
      // ユーザー
      this.hasUserErr = Object.keys(userErrs).length > 0;
      // フォーム
      const { inputs } = this.$store.state.gasApplicationInput;
      Object.keys(this.pages).forEach((index) => {
        const { fields } = this.pages[index];
        const thisPageFieldIdList = Object.keys(fields).map((fieldKey) => {
          return fields[fieldKey].field_id;
        });
        let findFormErr = inputs.find((input) => {
          const searchedIndex = thisPageFieldIdList.indexOf(input.field_id);
          const hasErr = input.errMessages.length > 0;
          return searchedIndex !== -1 && hasErr;
        });
        // 例外的にページにエラーマークをつけたいとき
        if (this.pageErrs.includes(Number(index))) {
          findFormErr = true;
        }
        this.hasPageErr.splice(index, 1, findFormErr);
      });
    },
    async withdraw() {
      if (!await this.confirm('提出内容はすべて破棄されます。この追加資料を削除してもよろしいですか？')) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        apptypeId: this.apptypeId,
      };
      const response = await api.send('/api/attachments/withdraw', param)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      await this.alert('追加資料を削除しました。');
      // トップへリダイレクト
      this.viewLocateMsg = false;
      this.$router.push({ name: 'gasTop' });
    },
    // ページ上部にスクロールし、スクロールバーを表示する。settimeoutがないと効かない。scrollUserinfoもなぜか効かない
    // createに書いてもだめ
    scrollBar(index) {
      if (!this.initTabChange[index]) {
        setTimeout(() => {
          document.querySelector('#scrollUserinfo').scrollTop = 1;
          Object.keys(this.pages).forEach((page) => {
            document.querySelector(`#scroll${page}`).scrollTop = 1;
          });
        }, 1);
        this.$set(this.initTabChange, index, true);
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.hasChange || to.name === 'gasAttachmentEnd' || this.viewLocateMsg === false) {
      next();
    } else if (await this.confirm('入力内容は破棄されます。このページから移動してもよろしいですか？')) {
      next();
    } else {
      next(false);
    }
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.$store.commit('gasApplicationForm/init');
    this.$store.commit('userInfo/resetErrorMsg');
    this.$store.commit('gasApplicationForm/setIsCms', false);
    const { apptypeId } = this.$route.params;
    this.apptypeId = apptypeId;
    this.$store.commit('gasApplicationForm/setReportTypeId', apptypeId);
    await this.fetchApptypeData(apptypeId);
    this.$store.dispatch('page/offLoading');

    setTimeout(() => {
      document.querySelector('#scrollUserinfo').scrollTop = 1;
    }, 0);
  },
};
</script>

<style scoped>
  .notice {
    background-color: #F3F3F3;
    height: 80px;
  }

  .bottonArea {
    width: 1100px;
  }
  .errorMark {
    position: absolute;
    top: -7px;
    left: 150px;
  }
  .warning {
    color: #dc3545;
  }
  #appForm {
    display: block;
  }
</style>
